import { BarcodeScannerAction, BARCODE_SCANNER_PRODUCT_RECEIVED, BARCODE_SCANNER_CLEARED } from './actions';
import { BarcodeScannerResultType } from './constants';
import type { Product } from './types';

type BarcodeScannerState = {
  resultType: string | null;
  product: Product | null;

};
const initialState: BarcodeScannerState = {
  resultType: null,
  product: null,
};

export default (state = initialState, action: BarcodeScannerAction) => {
  switch (action.type) {
    case BARCODE_SCANNER_PRODUCT_RECEIVED:
      const product = action.payload;
      if (!product)
        return { resultType: BarcodeScannerResultType.NotFound, product };

      return { resultType: BarcodeScannerResultType.Success, product };
    case BARCODE_SCANNER_CLEARED:
        return initialState;
    default:
      return state;
  }
};
