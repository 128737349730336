import type { State } from '../types';
import { createReducer } from 'utils/redux';
import { generateKey } from 'utils/helpers';
import { BarcodeScannerAction, BarcodeScannerLinesReceivedAction, BARCODE_SCANNER_LINES_RECEIVED } from '../actions.barcodeScanner';

export default createReducer<State, BarcodeScannerAction>(null as unknown as State, {
  [BARCODE_SCANNER_LINES_RECEIVED]: onLinesReceived,
});

function onLinesReceived(state: State, action: BarcodeScannerLinesReceivedAction) {
  const productLines = action.payload.lines;
  const lastModifiedLineId = productLines.list.length ? productLines.list[0].id : undefined;
  const list = productLines.list.map(line => {
    const id = line.id || generateKey();
    if ('configuration' in line)
      return { ...line, id };

    const subLines = line.subLines ? line.subLines.map(subLine => ({ ...subLine, id: subLine.id || generateKey() })) : line.subLines;
    return { ...line, id, subLines };
  });

  return {
    ...state,
    lastModifiedLineId,
    model: {
      ...state.model,
      productLines: {
        list,
        totalCount: productLines.totalCount,
      },
      serviceLines: [],
    },
    isQuickOrderMode: true,
  };
}