import type { Epic } from 'behavior/types';
import { ofType } from 'redux-observable';
import { concatMap, mergeMap, pluck } from 'rxjs/operators';
import { OrderMatrixPageAction, ORDER_MATRIX_SUBMITTED, unsetBlockIndicator } from './actions';
import { loadProductsInfoQuery } from './queries';
import { unsetLoadingIndicator } from 'behavior/loadingIndicator';
import { Action } from 'redux';
import { addProducts, Updaters } from 'behavior/basket';
import { toasts } from 'behavior/toasts';

type ProductsResponse = {
  catalog: {
    products: {
      products: {
        id: string;
        uom: {
          id: string;
        };
      }[];
    };
  };
};

const orderMatrixEpic: Epic<OrderMatrixPageAction> = (action$, _state$, { api }) => {
  const orderMatrixSubmitted$ = action$.pipe(
    ofType(ORDER_MATRIX_SUBMITTED),
    concatMap(({ payload: { input } }) => {
      const productIds = input.map(i => i.id);
      const options = {
        ids: productIds,
        page: {
          size: productIds.length,
        },
      };

      return api.graphApi<ProductsResponse>(loadProductsInfoQuery, { options }).pipe(
        pluck('catalog', 'products', 'products'),
        mergeMap(products => {
          const availableProductIds = products.map(p => p.id);
          const unavailableProductIds = productIds.filter(i => !availableProductIds.includes(i));

          const actions: Array<Action> = [
            unsetLoadingIndicator(),
            unsetBlockIndicator(),
          ];

          if (!availableProductIds.length) {
            toasts.error('', { textKey: 'OrderMatrix_AddProductsError', replacementValue: unavailableProductIds.join(', ') });
            return actions;
          }

          if (unavailableProductIds.length)
            toasts.error('', { textKey: 'OrderMatrix_AddProductsError', replacementValue: unavailableProductIds.join(', ') });

          const productLines = products.map(p => {
            const quantity = input.find(i => i.id === p.id)!.quantity;
            return {
              productId: p.id,
              quantity,
              uomId: p.uom.id,
            };
          });

          actions.push(addProducts(productLines, Updaters.OrderMatrix));

          return actions;
        }),
      );
    }),
  );

  return orderMatrixSubmitted$;
};

export default orderMatrixEpic;