import { PageComponentNames } from '../componentNames';
import { OrderMatrixPageAction, ORDER_MATRIX_NEXT_STEP, TOOTH_LINE_SELECTED, BLOCK_INDICATOR_SET, BLOCK_INDICATOR_UNSET } from './actions';
import { Steps } from './constants';
import type { ToothLine } from './types';

type State = {
  component: PageComponentNames.OrderMatrix;
  info: {
    toothLines: ToothLine[];
    toothLine: {
      id: string;
      code: string;
      form: string | null;
      shade: string | null;
    } | null;
  };
};

export default (state = null as unknown as State, action: OrderMatrixPageAction) => {
  switch (action.type) {
    case TOOTH_LINE_SELECTED:
      const { payload: { toothLine } } = action;
      return {
        ...state,
        info: {
          ...state.info,
          toothLine: {
            id: toothLine.id,
            code: toothLine.code,
          },
          stepInvalid: false,
          steps: [
            { id: Steps.Formular, isCompleted: true },
            { id: Steps.Matrix, isCompleted: false },
          ],
        },
      };
    case ORDER_MATRIX_NEXT_STEP:
      return {
        ...state,
        info: {
          ...state.info,
          stepInvalid: true,
          currentStep: action.payload.step,
        },
      };
    case BLOCK_INDICATOR_SET:
      return {
          ...state,
          isSubmitBlocked: true,
        };
    case BLOCK_INDICATOR_UNSET:
      return {
          ...state,
          isSubmitBlocked: false,
        };
    default:
      return state;
  }
};
