import styles from './Search.module.scss';
import { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { useOnChange } from 'utils/hooks';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Autosuggest, highlightStringBySpans } from 'components/primitives/autosuggest';
import { escapeRegexCharacters } from 'utils/helpers';
import { debounce } from 'lodash';
import { useHeaderContext } from 'components/sections/headerContext';
import SuggestionItem from './SuggestionItem';
import { SearchContainerContext } from './SearchContainerContext';
import SuggestionsContainer from './SuggestionsContainer';
import { searchProducts, searchProductsClear } from 'behavior/products/search/suggestions';
import { navigateTo } from 'behavior/events';
import { RouteName } from 'routes';
import { defaultSearchParams } from './constants';
import { trackProductClick, EventSource } from 'behavior/analytics';
import SearchButton from './SearchButton';
import { getThemeFontSizeClassName } from 'components/theme';
import { SimpleText } from 'components/sanaText';

const defaultDependencies = [];

const SearchBox = ({
  placeholder,
  searchText,
  searchTitle,
  searchProducts,
  searchProductsClear,
  suggestions,
  navigateTo,
  trackProductClick,
  routePath,
  noImageUrl,
  viewMode,
  routeData,
  prevRouteData,
  className = '',
  onFocus,
  onBlur,
  inputId,
  disabled,
  options,
  style,
  ariaLabel,
}) => {
  const headerContext = useHeaderContext();
  const formRef = useRef(null);
  const highlightedSuggestionRef = useRef();
  const [maxHeight, setMaxHeight] = useState('');
  const [value, setValue] = useState(headerContext.searchInputValue || '');
  const [showInfo, setShowInfo] = useState(true);
  const timeoutIdRef = useRef();
  if (headerContext.available)
    headerContext.searchInputValue = value;

  useOnChange(() => {
    if (!headerContext.available) {
      setValue('');
      return;
    }

    const routeName = routeData?.routeName;
    if (routeName === RouteName.Search && routeData.params.q) {
      setValue(routeData.params.q);
      return;
    }

    if (!value)
      return;

    const prevRouteName = prevRouteData?.routeName;

    if (
      prevRouteName
      && prevRouteName === routeName
      && prevRouteData.params.language !== routeData.params.language
    )
      return;

    if (
      prevRouteName === RouteName.Search
      && routeName === RouteName.ProductDetails
    )
      return;

    setValue('');
  }, [routeData, prevRouteData], false);

  useEffect(() => {
    const resizeHandler = debounce(() => {
      if (!formRef.current)
        return;

      setMaxHeight(calculateMaxHeight(formRef.current));
    }, 250);

    window.addEventListener('resize', resizeHandler);
    return () => window.removeEventListener('resize', resizeHandler);
  }, [formRef.current]);

  useEffect(() => () => clearTimeout(timeoutIdRef.current), []);

  const extendedOnFocus = useCallback(e => {
    setShowInfo(true);
    onFocus && onFocus(e);
  }, []);

  const search = useMemo(
    () => debounce(value => !disabled && searchProducts(value), 250),
    [disabled],
  );

  const onFetchRequested = useCallback(({ value, reason }) => {
    setMaxHeight(calculateMaxHeight(formRef.current));
    if (reason === 'input-changed' && value.length > 1)
      search(value);
  }, defaultDependencies);

  const onSelected = useCallback((event, { suggestion }) => {
    event.preventDefault();
    searchProductsClear();
    setValue('');

    // setTimeout to prevent combobox announcing about aria-activedescendant change after navigation.
    timeoutIdRef.current = setTimeout(() => {
      setShowInfo(false);
      if (suggestion.productGroup) {
        navigateTo(suggestion.productGroup.routeData, suggestion.productGroup.url);
        return;
      }

      trackProductClick({
        product: suggestion,
        source: EventSource.SearchInput,
      });

      navigateTo(suggestion.routeData, suggestion.url);
    }, 0);
  }, defaultDependencies);

  const onSubmit = useCallback(event => {
    event.preventDefault();
    if (disabled)
      return;
    searchProductsClear();
    const encodedValue = encodeURIComponent(value);
    const searchURL = routePath.replace(defaultSearchParams, encodedValue);

    navigateTo({ routeName: RouteName.Search, params: { q: value, viewMode } }, searchURL);

    document.getElementById('layout').focus();
  }, [routePath, value, disabled]);

  const onHighlighted = ({ suggestion }) => {
    highlightedSuggestionRef.current = suggestion;
  };

  const renderItem = useCallback(suggestion => (
    <SuggestionItem suggestion={suggestion} options={options} />
  ), defaultDependencies);
  const onChange = useCallback((_event, { newValue }) => setValue(newValue), defaultDependencies);

  const onKeyDown = e => {
    if (highlightedSuggestionRef.current && e.key === 'Tab') {
      onSelected(e, { suggestion: highlightedSuggestionRef.current });
      e.preventDefault();
    }
  };

  const inputProps = {
    id: inputId,
    placeholder,
    value,
    onChange,
    onFocus: extendedOnFocus,
    onBlur,
    onKeyDown,
    name: 'q',
  };

  const memorizedSuggestions = useMemo(() => getSuggestions(value, suggestions, noImageUrl), [suggestions]);
  const searchPathWithoutParams = useMemo(() => routePath ? routePath.split('?')[0] : '', [routePath]);

  return (
    <div
      style={style}
      className={`${styles.searchBox} ${className} ${getThemeFontSizeClassName(options.boxThemeFontSize)}`}
    >
      <form role="search" aria-label={ariaLabel} method="get" action={searchPathWithoutParams} onSubmit={onSubmit} ref={formRef}>
        <SearchContainerContext.Provider value={{ maxHeight }}>
          <label className="visually-hidden" htmlFor={inputId}><SimpleText textKey="Search" /></label>
          <Autosuggest
            suggestions={memorizedSuggestions}
            onFetchRequested={onFetchRequested}
            onClearRequested={searchProductsClear}
            onSelected={onSelected}
            onHighlighted={onHighlighted}
            getItemValue={getItemValue}
            renderItem={renderItem}
            renderItemsContainer={SuggestionsContainer}
            inputProps={inputProps}
            theme={styles}
            id={inputId} // Suffix to be added to 'react-autowhatever-' to resolve ID duplicates conflict.
            showInfo={showInfo}
          />
        </SearchContainerContext.Provider>
        <SearchButton text={searchText} title={searchTitle} options={options} />
      </form>
    </div>
  );
};

const routeDataPropType = PropTypes.shape({
  routeName: PropTypes.string,
  params: PropTypes.shape({
    q: PropTypes.string,
    language: PropTypes.number,
  }),
});

SearchBox.propTypes = {
  placeholder: PropTypes.string,
  searchText: PropTypes.string,
  searchTitle: PropTypes.string,
  searchProducts: PropTypes.func.isRequired,
  searchProductsClear: PropTypes.func.isRequired,
  suggestions: PropTypes.array.isRequired,
  routePath: PropTypes.string,
  navigateTo: PropTypes.func.isRequired,
  trackProductClick: PropTypes.func.isRequired,
  noImageUrl: PropTypes.string,
  viewMode: PropTypes.string,
  routeData: routeDataPropType,
  prevRouteData: routeDataPropType,
  className: PropTypes.string,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  inputId: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  options: PropTypes.shape({
    boxThemeFontSize: PropTypes.string.isRequired,
  }).isRequired,
  style: PropTypes.object,
  ariaLabel: PropTypes.string,
};

const mapStateToProps = ({
  suggestions,
  settings: { product, search },
  routing: { routeData, previous },
}) => ({
  suggestions: suggestions.products,
  noImageUrl: product ? product.noImage.small : null,
  viewMode: search && search.defaultViewMode,
  routeData,
  prevRouteData: previous?.routeData,
});

export default connect(
  mapStateToProps,
  { searchProducts, searchProductsClear, navigateTo, trackProductClick },
)(SearchBox);

function getSuggestions(value, products, noImageUrl) {
  const escapedValue = escapeRegexCharacters(value.trim());
  const regex = new RegExp(`(${escapedValue})`, 'gi');

  return products
    .map(product => product.productGroup ? {
      ...product,
      id: product.productGroup.id,
      imageUrl: product.productGroup.imageUrl ?? noImageUrl,
      highlightedText: highlightStringBySpans(`${product.productGroup.title || ''} - ${product.productGroup.id}`, regex, styles.highlight),
    } : {
      ...product,
      imageUrl: product.imageUrl ? product.imageUrl : noImageUrl,
      highlightedText: highlightStringBySpans(`${product.title} - ${product.id}`, regex, styles.highlight),
    });
}

function getItemValue(suggestion) {
  return suggestion.title;
}

function calculateMaxHeight(form) {
  const innerHeight = window.innerHeight;
  return innerHeight - form.getBoundingClientRect().bottom + 'px';
}
