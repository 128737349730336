import type { Handler } from '../types';
import { PageComponentNames } from 'behavior/pages';
import { RouteName } from 'routes';
import { map } from 'rxjs/operators';
import { MyAccountPage } from './types';
import { ContentSystemPageData, initContentSystemPage, loadContentSystemPageQuery } from '../system';

const handler: Handler<MyAccountRouteData, MyAccountPage> = (_routeData, _state$, { api }) => api.graphApi<MyAccountPageResponse>(loadContentSystemPageQuery('myAccount')).pipe(
  map(({ pages: { myAccount } }) => ({
    page: {
      ...initContentSystemPage(myAccount),
      component: PageComponentNames.MyAccount as const,
    },
  })),
);

export default handler;

type MyAccountRouteData = {
  routeName: RouteName.MyAccount;
};

type MyAccountPageResponse = {
  pages: {
    myAccount: ContentSystemPageData;
  };
};
