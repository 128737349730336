import type { Product } from './types';

export const BARCODE_SCANNER_SEARCH_PRODUCT = 'BARCODE_SCANNER/PRODUCT/SEARCH' as const;
export const searchProductByBarcode = (barcode: string) => ({
  type: BARCODE_SCANNER_SEARCH_PRODUCT,
  payload: { barcode },
});

export const BARCODE_SCANNER_PRODUCT_RECEIVED = 'BARCODE_SCANNER/PRODUCT/RECEIVED' as const;
export const productByBarcodeReceived = (product: Product | null) => ({
  type: BARCODE_SCANNER_PRODUCT_RECEIVED,
  payload: product,
});

export const BARCODE_SCANNER_CLEARED = 'BARCODE_SCANNER/CLEARED' as const;
export const clearBarcodeScanner = () => ({
  type: BARCODE_SCANNER_CLEARED,
});

export type BarcodeScannerAction = ReturnType<
  | typeof searchProductByBarcode
  | typeof productByBarcodeReceived
  | typeof clearBarcodeScanner
>;