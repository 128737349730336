import type { ModifiedLine, QuickOrderBasket } from './types';
import { filterExistingImages } from 'utils/helpers';

type BarcodeScannerAddedProduct = {
  productId: string;
  addWithDefaultQuantity: boolean;
  variantId: string | null;
  uomId: string | null;
  quantity: number | null;
};
export const BARCODE_SCANNER_ADD_PRODUCTS = 'BARCODE_SCANNER_ADD_PRODUCTS' as const;
export const barcodeScannerAddProducts = (added: BarcodeScannerAddedProduct[], modified: ModifiedLine[], size: number) => ({
  type: BARCODE_SCANNER_ADD_PRODUCTS,
  payload: {
    added,
    modified,
    size,
  },
});

export const BARCODE_SCANNER_LINES_RECEIVED = 'BARCODE_SCANNER_LINES_RECEIVED' as const;
export const barcodeScannerLinesReceived = (lines: BarcodeScannerProductLines) => ({
  type: BARCODE_SCANNER_LINES_RECEIVED,
  payload: { lines: filterImages(lines) },
});

function filterImages(lines: BarcodeScannerProductLines) {
  for (const line of lines.list)
    if ('product' in line && line.product?.images)
      line.product.images = filterExistingImages(line.product.images);

  return lines;
}

type BarcodeScannerProductLines = QuickOrderBasket['productLines'];

export type BarcodeScannerLinesReceivedAction = ReturnType<typeof barcodeScannerLinesReceived>;
export type BarcodeScannerAction =
  | BarcodeScannerLinesReceivedAction
  | ReturnType<typeof barcodeScannerAddProducts>;
