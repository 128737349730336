import styles from './ScrollDownIcon.module.scss';

import { memo, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import scrollIntoView from 'scroll-into-view';
import { useLayoutShifter } from 'utils/layout';
import { scroll$ } from 'utils/rxjs';
import { merge, of } from 'rxjs';
import { useSanaTexts } from 'components/sanaText';
import { usePrintMode } from 'utils/hooks';
import { useIsVisualDesigner } from 'components/visualDesigner';

const isPartlyHidden = blockRef => {
  if (!blockRef.current)
    return false;

  const clientRect = blockRef.current.getBoundingClientRect();
  const overflowLimit = clientRect.height * 0.66;

  return Math.abs(clientRect.top) > overflowLimit;
};

const ScrollDownIcon = ({ aimRef }) => {
  const isPrintMode = usePrintMode();
  const isVisualDesigner = useIsVisualDesigner();
  const [buttonTitle] = useSanaTexts(['ButtonAltText_ScrollDown']).texts;
  const { topFixedElementsHeight } = useLayoutShifter();
  const cancelRef = useRef(null);
  const buttonRef = useRef(null);

  useEffect(() => {
    if (!cancelRef.current)
      return;

    cancelRef.current();
    cancelRef.current = null;

    scrollIntoView(aimRef.current, {
      time: 250,
      align: { top: 0, topOffset: -aimRef.current.clientHeight - parseInt(aimRef.current.style.marginBottom, 10) + topFixedElementsHeight },
    });
  }, [topFixedElementsHeight]);

  const handleClick = useCallback(() => {
    if (isVisualDesigner)
      return;

    cancelRef.current = scrollIntoView(aimRef.current, {
      time: 750,
      align: { top: 0, topOffset: -aimRef.current.clientHeight - parseInt(aimRef.current.style.marginBottom, 10) + topFixedElementsHeight },
    }, () => void (cancelRef.current = null));
  }, [topFixedElementsHeight, isVisualDesigner]);

  useEffect(() => {
    const actions$ = merge(of(1), scroll$);
    const eventSubscription = actions$.subscribe(() => {
      if (buttonRef.current)
        buttonRef.current.classList[!isVisualDesigner && isPartlyHidden(aimRef) ? 'add' : 'remove'](styles.hidden);
    });

    return () => {
      eventSubscription.unsubscribe();
    };
  }, [isVisualDesigner]);

  if (isPrintMode)
    return null;

  return (
    <button
      type="button"
      onClick={handleClick}
      className={styles.button}
      ref={buttonRef}
      title={buttonTitle}
    >
      <div className={styles.chevron} />
      <div className={styles.chevron} />
      <div className={styles.chevron} />
    </button>);
};

ScrollDownIcon.propTypes = {
  aimRef: PropTypes.object,
};

export default memo(ScrollDownIcon);