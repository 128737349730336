import { RouteName } from 'routes';
import { map } from 'rxjs/operators';
import { PageComponentNames } from '../componentNames';
import { initPageContent, SystemPage } from '../system';
import { Handler } from '../types';
import { Steps } from './constants';
import { loadOrderMatrixPageQuery } from './queries';
import { LoadOrderMatrixPageResponse, ToothLine } from './types';

type OrderMatrixData = {
  routeName: RouteName.OrderMatrix;
  options?: {
    step: string | null;
  };
};

type OrderMatrixPage = SystemPage & {
  component: PageComponentNames.OrderMatrix;
  info: {
    toothLines: ToothLine[];
    currentStep: string | null;
    steps: {
      id: Steps;
      isCompleted: boolean;
    }[];
  };
};

const handler: Handler<OrderMatrixData, OrderMatrixPage> = (routeData, _state$, { api }) => api.graphApi<LoadOrderMatrixPageResponse>(loadOrderMatrixPageQuery).pipe(
  map(({ pages: { orderMatrix } }) => {
    const result = {
      page: initPageContent({ 
        metaTitle: orderMatrix.metaTitle, 
        content: orderMatrix.content,
       }) as OrderMatrixPage,
    };

    result.page.info = {
      toothLines: orderMatrix.toothLines,
      currentStep: routeData?.options?.step ?? null,
      steps: [
        { id: Steps.Formular, isCompleted: false },
        { id: Steps.Matrix, isCompleted: false },
      ],
    };
    result.page.component = PageComponentNames.OrderMatrix;

    return result;
  }),
);

export default handler;