import type { InvoiceResult } from './types';
import type { AdditionalCustomerData, ExtraPaymentData } from 'behavior/pages/checkout';
import {
  CREATE_INVOICE_ORDER_RESULT_RECEIVED,
  SUBMIT_INVOICE_ORDER_RESULT_RECEIVED,
  SUBMIT_INVOICE_ORDER,
  PAYMENT_METHOD_EXTRA_DATA_RECEIVED,
  InvoicePaymentAction,
  CreateInvoiceOrderResultReceivedAction,
  SubmitInvoiceOrderResultReceivedAction,
  ReceivePaymentMethodExtraData,
} from './actions';
import { createReducer } from 'utils/redux';

export type InvoicePaymentState = {
  createInvoiceOrderResult?: InvoiceResult | null;
  submitInvoiceOrderResult?: InvoiceResult | null;
  isInvoiceOrderSubmitting?: boolean;
  additionalCustomerData?: AdditionalCustomerData;
  extraPaymentData?: { paymentMethodId: string } & ExtraPaymentData;
};

export default createReducer<InvoicePaymentState, InvoicePaymentAction>(null as any, {
  [CREATE_INVOICE_ORDER_RESULT_RECEIVED]: onCreateInvoiceOrderResultReceived,
  [SUBMIT_INVOICE_ORDER_RESULT_RECEIVED]: onSubmitInvoiceOrderResultReceived,
  [SUBMIT_INVOICE_ORDER]: onSubmitInvoiceOrder,
  [PAYMENT_METHOD_EXTRA_DATA_RECEIVED]: onPaymentMethodExtraDataReceived,
});

function onCreateInvoiceOrderResultReceived(state: InvoicePaymentState, action: CreateInvoiceOrderResultReceivedAction) {
  return {
    ...state,
    createInvoiceOrderResult: action.payload.result,
  };
}

function onSubmitInvoiceOrderResultReceived(state: InvoicePaymentState, action: SubmitInvoiceOrderResultReceivedAction) {
  return {
    ...state,
    submitInvoiceOrderResult: action.payload.result,
    isInvoiceOrderSubmitting: false,
  };
}

function onSubmitInvoiceOrder(state: InvoicePaymentState) {
  return {
    ...state,
    submitInvoiceOrderResult: null,
    isInvoiceOrderSubmitting: true,
  };
}

function onPaymentMethodExtraDataReceived(state: InvoicePaymentState, action: ReceivePaymentMethodExtraData) {
  const { paymentMethodId, additionalCustomerData, extraPaymentData } = action.payload;

  return {
    ...state,
    additionalCustomerData,
    extraPaymentData: extraPaymentData && { paymentMethodId, ...extraPaymentData },
  };
}
