import type { Product } from './types';
import { productByBarcodeReceived, BarcodeScannerAction, BARCODE_SCANNER_SEARCH_PRODUCT } from './actions';
import type { Epic } from 'behavior/types';
import { ofType } from 'redux-observable';
import { switchMap, map, pluck } from 'rxjs/operators';
import { retryWithToast } from 'behavior/errorHandling';
import { searchProductByBarcodeQuery } from './queries';

type ResponseProduct = {
  id: string;
  title: string | null;
  uom: {
    id: string;
    minimumQuantity: number | null;
  } | null;
};

type SearchProductsByBarcodeResponse = {
  catalog: {
    barcodeScanner: {
      product: ResponseProduct;
    };
  };
};

const epic: Epic<BarcodeScannerAction> = (action$, state$, { api, logger }) => action$.pipe(
  ofType(BARCODE_SCANNER_SEARCH_PRODUCT),
  pluck('payload', 'barcode'),
  switchMap(barcode => {
    return api.graphApi<SearchProductsByBarcodeResponse>(searchProductByBarcodeQuery, { barcode }).pipe(
      map(mapProduct),
      retryWithToast(action$, logger),
    );
  }),
);

export default epic;

export function mapProduct(data: SearchProductsByBarcodeResponse) {
  const product = data.catalog.barcodeScanner?.product;

  if (product == null)
    return productByBarcodeReceived(null);

  const scannedProduct: Product = {
    ...product,
  };

  return productByBarcodeReceived(scannedProduct);
}
