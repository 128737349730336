import type { Action } from 'redux';
import type { Epic } from 'behavior/types';
import { of, concat, throwError } from 'rxjs';
import { concatMap, catchError, mergeMap, startWith, withLatestFrom, pluck } from 'rxjs/operators';
import { basketUpdated } from '../actions';
import { quickOrderAddProductsMutation } from '../queries.quickOrder';
import { retryWithToast } from 'behavior/errorHandling';
import { setLoadingIndicator, unsetLoadingIndicator } from 'behavior/loadingIndicator';
import { Updaters } from '../constants';
import { ofType } from 'redux-observable';
import { basketChangeStarted, basketChangeCompleted } from 'behavior/events';
import { trackAddToBasket, trackRemoveFromBasket, getModifiedProductsTrackingData } from 'behavior/analytics';
import { BarcodeScannerAction, barcodeScannerLinesReceived, BARCODE_SCANNER_ADD_PRODUCTS } from '../actions.barcodeScanner';
import { ModifiedLines, QuickOrderBasket } from '../types';

const epic: Epic<BarcodeScannerAction> = (action$, state$, { api, logger }) => action$.pipe(
  ofType(BARCODE_SCANNER_ADD_PRODUCTS),
  pluck('payload'),
  withLatestFrom(state$),
  concatMap(([{ added, modified, size }, state]) => {
    const variables = {
      lines: added,
      size,
      hasModifiedLines: !!modified?.length,
      requestModifiedLines: state.analytics?.isTrackingEnabled,
      updateInput: { modified },
    };

    const isTrackingEnabled = !!state.analytics?.isTrackingEnabled;
    const loadCategories = isTrackingEnabled;
    const loadMasterProduct = isTrackingEnabled;

    return api.graphApi<BarcodeScannerAddProductsMutationResponse>(quickOrderAddProductsMutation({
      loadCategories,
      loadMasterProduct,
    }), variables).pipe(
      mergeMap(({ basket }) => {
        const updatedBasket = basket.addProducts.basket;
        const actions: Array<Action> = [
          unsetLoadingIndicator(),
          basketUpdated(Updaters.BarcodeScanner, +new Date(updatedBasket.modifiedDate)),
          barcodeScannerLinesReceived(updatedBasket.productLines),
          basketChangeCompleted(added.length),
        ];

        const modifiedLines = basket.update?.modifiedLines?.list;
        const addedLines = basket.addProducts.modifiedLines?.list;
        if (modifiedLines || addedLines) {
          const { addedProducts, removedProducts } = getModifiedProductsTrackingData(state.basket.model!.productLines.list!, modifiedLines, addedLines);

          if (addedProducts?.length)
            actions.push(trackAddToBasket({ products: addedProducts }));

          if (removedProducts?.length)
            actions.push(trackRemoveFromBasket({ products: removedProducts }));
        }

        return actions;
      }),
      catchError(
        e => concat(of(unsetLoadingIndicator(), basketChangeCompleted(0)), throwError(e)),
      ),
      retryWithToast(action$, logger),
      startWith(setLoadingIndicator(), basketChangeStarted()),
    );
  }),
);

export default epic;

type BarcodeScannerAddProductsMutationResponse = {
  basket: {
    update?: {
      modifiedLines?: ModifiedLines | null;
    };
    addProducts: {
      modifiedLines?: ModifiedLines | null;
      basket: QuickOrderBasket;
    };
  };
};
