import loadable from '@loadable/component';
import { PageComponentNames } from 'behavior/pages';

const Orders = loadable(() => import(/*webpackChunkName:"docs"*/'./orders'));
const Quotes = loadable(() => import(/*webpackChunkName:"docs"*/'./quotes'));
const Invoices = loadable(() => import(/*webpackChunkName:"docs"*/'./invoices'));
const ReturnOrders = loadable(() => import(/*webpackChunkName:"docs"*/'./returnOrders'));
const CreditNotes = loadable(() => import(/*webpackChunkName:"docs"*/'./creditNotes'));
const ReturnReceipts = loadable(() => import(/*webpackChunkName:"docs"*/'./returnReceipts'));
const Shipments = loadable(() => import(/*webpackChunkName:"docs"*/'./shipments'));

/* eslint-disable react/no-multi-comp */
export default {
  [PageComponentNames.Orders]: _ => <Orders />,
  [PageComponentNames.Quotes]: _ => <Quotes />,
  [PageComponentNames.Invoices]: _ => <Invoices />,
  [PageComponentNames.ReturnOrders]: _ => <ReturnOrders />,
  [PageComponentNames.CreditNotes]: _ => <CreditNotes />,
  [PageComponentNames.ReturnReceipts]: _ => <ReturnReceipts />,
  [PageComponentNames.Shipments]: _ => <Shipments />,
};
