import styles from './Toast.module.scss';
import { useEffect, useRef, useState, useCallback, useMemo, cloneElement, isValidElement } from 'react';
import PropTypes from 'prop-types';
import { joinClasses } from 'utils/helpers';
import { SuccessIcon, ErrorIcon, WarningIcon, InfoIcon, CrossBigIcon } from 'components/primitives/icons';
import { noop } from 'rxjs';
import ReactResizeDetector from 'react-resize-detector';
import { useSanaTexts } from 'components/sanaText';
import { makeRichText } from 'utils/render';

const Icons = {
  success: <SuccessIcon className={styles.icon} />,
  error: <ErrorIcon className={styles.icon} />,
  errorDialog: <ErrorIcon className={styles.icon} />,
  warning: <WarningIcon className={styles.icon} />,
  info: <InfoIcon className={styles.icon} />,
};

const DefaultToast = ({
  appearance = 'info',
  autoDismiss,
  autoDismissTimeout,
  children,
  isRunning,
  onDismiss = noop,
  transitionDuration,
  transitionState,
  onMouseEnter = noop,
  onMouseLeave = noop,
  className,
  textKey,
  replacementValue,
  toastId,
}) => {
  const elementRef = useRef();
  const prevFocusedElRef = useRef();
  const autoFocusElRef = useRef();

  const [height, setHeight] = useState('auto');
  const [shown, setShown] = useState();
  const [hasContent, setHasContent] = useState();

  const [text, ariaToastClose] = useSanaTexts([!children && textKey, ...preload.texts]).texts;

  const containerStyle = { transitionDuration: transitionDuration + 'ms', height };
  const frameStyle = { transitionDuration: transitionDuration + 'ms' };
  // Delay countdown animation, since on Safari animations may freeze when few animations run simultaneously.
  const counterStyle = { animationDuration: autoDismissTimeout - transitionDuration + 'ms', animationDelay: transitionDuration + 'ms' };

  const handleEscape = e => {
    if (e.key === 'Escape' || e.key === 'Esc')
      onDismiss(e);
  };

  useEffect(() => {
    switch (transitionState) {
      case 'entering':
        elementRef.current.getClientRects();
        setParentElementMinHeight(elementRef.current);
        setShown(true);
        return;

      case 'entered':
        setHeight(elementRef.current.scrollHeight + 'px');
        setParentElementMinHeight(elementRef.current);
        return;

      case 'exiting':
        setShown(false);
        setHeight(0);
        return;

      default:
        return;
    }
  }, [transitionState]);

  useEffect(() => () => {
    const { innerHeight } = window;
    const { style, children, dataset } = elementRef.current.parentElement;
    const newActualHeight = dataset.actualHeight - elementRef.current.scrollHeight;
    if (newActualHeight > innerHeight) {
      dataset.actualHeight = newActualHeight;
      return;
    }

    if (children.length > 1) {
      style.minHeight = newActualHeight + 'px';
      dataset.actualHeight = newActualHeight;
    } else {
      style.minHeight = '';
      dataset.actualHeight = 0;
    }
  }, []);

  const handleBottomBlockResize = useCallback(() => {
    if (transitionState !== 'entered')
      return;

    elementRef.current.style.height = '';
    setHeight(elementRef.current.scrollHeight + 'px');
    setParentElementMinHeight(elementRef.current);
  }, [elementRef, transitionState]);

  const containerClass = joinClasses(
    styles.toast,
    appearance,
    !isRunning && 'pause',
    shown && 'show',
    autoDismiss && 'countdown',
    className,
  );

  const isErrorDialog = appearance === 'errorDialog';

  const handleFocus = !isErrorDialog
    ? null
    : e => {
      const relatedTargetFromOutside = e.relatedTarget && !e.currentTarget.contains(e.relatedTarget);
      if (relatedTargetFromOutside)
        prevFocusedElRef.current = e.relatedTarget;

      if (!e.target.hasAttribute('data-focus-bound'))
        return;

      if (
        relatedTargetFromOutside && e.target === e.currentTarget.lastElementChild
        || !relatedTargetFromOutside && e.target === e.currentTarget.firstElementChild
      )
        e.currentTarget.querySelector(`.${styles.close} button`).focus();
      else
        e.currentTarget.querySelector('button,a').focus();
    };

  useEffect(() => {
    if (!isErrorDialog)
      return;

    prevFocusedElRef.current = document.activeElement;
    return () => {
      document.body.contains(prevFocusedElRef.current) && prevFocusedElRef.current.focus();
    };
  }, [isErrorDialog]);

  useEffect(() => {
    if (hasContent)
      return;

    if (children) {
      const contentEl = elementRef.current.querySelector(`.${styles.content}`);
      if (contentEl.textContent) {
        setHasContent(true);
        return;
      }

      // Children components with actual text content could be not rendered at the moment useEffect executes, for this case MutationObserver should be added.
      const observer = new MutationObserver(() => {
        // In some rare cases MutationObserver can run right before component unmount before cleanup callback, in this case elementRef.current will be null.
        if (!elementRef.current || !contentEl.textContent)
          return;

        observer.disconnect();
        setHasContent(true);
      });
      const mutationOptions = { childList: true, subtree: true };
      observer.observe(contentEl, mutationOptions);

      return () => {
        observer.disconnect();
      };
    }

    if (text)
      setHasContent(true);
  }, [hasContent, !!children, text]);

  useEffect(() => {
    if (!isErrorDialog || transitionState !== 'entered' || !hasContent)
      return;

    const autoFocusEl = autoFocusElRef.current || elementRef.current.querySelector('button,a');
    autoFocusEl.focus();
  }, [transitionState, hasContent, isErrorDialog]);

  const [labelId, descriptionId] = useMemo(() => [`${toastId}Lbl`, `${toastId}Dsc`], [toastId]);
  const isErrorDialogComponent = isErrorDialog && isValidElement(children);

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      onFocus={handleFocus}
      onKeyDown={handleEscape}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={containerClass}
      style={containerStyle}
      ref={elementRef}
      role={isErrorDialog && transitionState === 'entered' && hasContent ? 'alertdialog' : null}
      aria-labelledby={isErrorDialog ? labelId : null}
      aria-describedby={isErrorDialogComponent ? descriptionId : null}
    >
      {isErrorDialog && <div className="visually-hidden" tabIndex="0" data-focus-bound />}
      <div className={styles.body}>
        <div className={styles.frame} style={frameStyle}>
          <div className={styles.side} aria-hidden>
            {autoDismiss && <div className={styles.counter} style={counterStyle} />}
            {Icons[appearance]}
          </div>
          {isErrorDialogComponent
            ? (
              <div className={styles.content}>
                {cloneElement(children, {
                  ref: autoFocusElRef,
                  labelId,
                  descriptionId,
                })}
                <ReactResizeDetector handleHeight onResize={handleBottomBlockResize} />
              </div>
            )
            : (
              <>
                {/* Aria-live region is used instead of role="alert" which is more suitable for this case because NVDA screen reader in Firefox skips
                    alerts announce if such block is added in DOM along with other DOM changes, e.g. on page transitions. */}
                {!isErrorDialog &&
                  <div
                    className="visually-hidden"
                    role="status"
                    aria-live="polite"
                    aria-atomic
                    aria-labelledby={transitionState === 'entered' && hasContent ? labelId : null}
                  />
                }
                <div className={styles.content} id={labelId}>
                  {children || (replacementValue ? makeRichText(text, [replacementValue]) : text)}
                  <ReactResizeDetector handleHeight onResize={handleBottomBlockResize} />
                </div>
              </>

            )}
          <div className={styles.close}>
            <button onClick={onDismiss}>
              <span className="visually-hidden">{ariaToastClose}</span>
              <CrossBigIcon aria-hidden />
            </button>
          </div>
        </div>
      </div>
      {isErrorDialog && <div className="visually-hidden" tabIndex="0" data-focus-bound />}
    </div>
  );
};

DefaultToast.propTypes = {
  appearance: PropTypes.oneOf(['success', 'error', 'errorDialog', 'warning', 'info']),
  autoDismiss: PropTypes.bool,
  autoDismissTimeout: PropTypes.number,
  children: PropTypes.node.isRequired,
  isRunning: PropTypes.bool,
  onDismiss: PropTypes.func,
  transitionDuration: PropTypes.number,
  transitionState: PropTypes.oneOf(['entering', 'entered', 'exiting', 'exited']).isRequired,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  className: PropTypes.string,
  textKey: PropTypes.string,
  replacementValue: PropTypes.string,
  toastId: PropTypes.string.isRequired,
};

export default DefaultToast;

export const preload = {
  texts: [
    'Aria_Toast_Close',
  ],
};

function setParentElementMinHeight(element) {
  const { innerHeight } = window;
  const { parentElement } = element;
  let {
    'padding-top': paddingTop,
    'padding-bottom': paddingBottom,
    'border-top-width': borderTopWidth,
    'border-bottom-width': borderBottomWidth,
  } = window.getComputedStyle(parentElement);
  paddingTop = parseInt(paddingTop || 0, 10);
  paddingBottom = parseInt(paddingBottom || 0, 10);
  borderTopWidth = parseInt(borderTopWidth || 0, 10);
  borderBottomWidth = parseInt(borderBottomWidth || 0, 10);
  let actualHeight = paddingTop + paddingBottom + borderTopWidth + borderBottomWidth;

  for (const { scrollHeight } of parentElement.children)
    actualHeight += scrollHeight;

  parentElement.dataset.actualHeight = actualHeight;
  parentElement.style.minHeight = (actualHeight > innerHeight ? innerHeight : actualHeight) + 'px';
}
