import type { DomElement, Transform } from './types';
import parse, { HTMLReactParserOptions, DOMNode, domToReact } from 'html-react-parser';
import imageTransformer from './transformImage';
import linkTransformer from './transformLink';

const renderHTML = (html: string | null | undefined, ...transformers: Transform[]) => {
  if (!html)
    return '';

  transformers = [...transformers, linkTransformer, imageTransformer];

  const options: HTMLReactParserOptions = {
    replace: (domNode): ReturnType<Transform> => {
      for (const transformer of transformers) {
        const transformResult = transformer(domNode as DomElement, renderChildren);

        if (transformResult)
          return transformResult;
      }
    },
  };

  const renderChildren = (nodes: DomElement[]) => domToReact(nodes as DOMNode[], options);

  return parse(html, options);
};

export default renderHTML;
