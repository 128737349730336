import type { Epic } from 'behavior/types';
import type { Product, Suggestion } from './types';
import type { LoadedSettings } from 'behavior/settings';
import { ofType } from 'redux-observable';
import { merge } from 'rxjs';
import { mergeMap, pluck, map } from 'rxjs/operators';
import { retryWithToast } from 'behavior/errorHandling';
import { routesBuilder } from 'routes';
import {
  ProductSelectorAction,
  PRODUCT_SELECTOR_SEARCH_SUGGESTIONS_REQUESTED,
  PRODUCT_SELECTOR_PRODUCT_REQUESTED,
  receiveSearchSuggestions,
  receiveProduct,
} from './actions';
import { productSearchSuggestionsQuery, productQuery } from './queries';

type ProductSearchSuggestionsResponse = {
  catalog: {
    quickSearch: {
      products: Suggestion[];
    };
  };
};

type ProductQueryResponse = {
  catalog: {
    products: {
      products: Product[];
    };
  };
};

const epic: Epic<ProductSelectorAction> = (action$, state$, { api, logger }) => {
  const getQueryOptions = () => {
    const settings = state$.value.settings as LoadedSettings;

    return {
      isProductGroupingEnabled: settings && settings.product.productGrouping.isEnabled,
    };
  };

  const searchProducts$ = action$.pipe(
    ofType(PRODUCT_SELECTOR_SEARCH_SUGGESTIONS_REQUESTED),
    pluck('payload'),
    mergeMap(({ keywords, count, ignoreGrouping }) => api.graphApi<ProductSearchSuggestionsResponse>(
      productSearchSuggestionsQuery(getQueryOptions()), {
      options: { keywords, count, ignoreGrouping },
    }).pipe(
      pluck('catalog', 'quickSearch', 'products'),
      map(receiveSearchSuggestions),
      retryWithToast(action$, logger),
    )),
  );

  const requestProduct$ = action$.pipe(
    ofType(PRODUCT_SELECTOR_PRODUCT_REQUESTED),
    pluck('payload'),
    mergeMap(payload => {
      const params = {
        options: { ids: [payload.id], ignoreGrouping: payload.ignoreGrouping },
        loadCategories: !!state$.value.analytics?.isTrackingEnabled,
        skipOrderable: payload.ignoreOrderable,
      };
      return api.graphApi<ProductQueryResponse>(productQuery(getQueryOptions()), params).pipe(
        pluck('catalog', 'products', 'products'),
        map(products => {
          const product = products[0];
          if (params.skipOrderable)
            product.isOrderable = true;
          product.routeData = routesBuilder.forProduct(product.id);

          if (product.productGroup)
            product.productGroup.routeData = routesBuilder.forProductGroup(product.productGroup.id);

          return receiveProduct(product);
        }),
        retryWithToast(action$, logger),
      );
    }),
  );

  return merge(searchProducts$, requestProduct$);
};

export default epic;
