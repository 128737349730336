import { Steps } from './constants';
import { ProductLineInput, ToothLine } from './types';

export const TOOTH_LINE_SELECTED = 'TOOTH_LINE_SELECT' as const;
export const selectToothLine = (toothLine: ToothLine) => ({
  type: TOOTH_LINE_SELECTED,
  payload: { toothLine },
});

export const ORDER_MATRIX_NEXT_STEP = 'ORDER_MATRIX/NEXT_STEP' as const;
export const orderMatrixNextStep = (step: Steps) => ({
  type: ORDER_MATRIX_NEXT_STEP,
  payload: { step },
});

export const ORDER_MATRIX_SUBMITTED = 'ORDER_MATRIX_SUBMITTED' as const;
export const submitOrderMatrix = (input: ProductLineInput[]) => ({
  type: ORDER_MATRIX_SUBMITTED,
  payload: { input },
});

export const BLOCK_INDICATOR_SET = 'BLOCK_INDICATOR_SET' as const;
export const setBlockIndicator = () => ({
    type: BLOCK_INDICATOR_SET,
});

export const BLOCK_INDICATOR_UNSET = 'BLOCK_INDICATOR_UNSET' as const;
export const unsetBlockIndicator = () => ({
    type: BLOCK_INDICATOR_UNSET,
});

export type OrderMatrixPageAction = ReturnType<
  | typeof selectToothLine
  | typeof orderMatrixNextStep
  | typeof submitOrderMatrix
  | typeof setBlockIndicator
  | typeof unsetBlockIndicator
>;